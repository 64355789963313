export var SignalrTopic;
(function (SignalrTopic) {
    SignalrTopic["tw_futures"] = "tw_futures";
    SignalrTopic["tw_options"] = "tw_options";
    SignalrTopic["tw_stocks_50"] = "tw_stocks_50";
    SignalrTopic["tw_stocks_all"] = "tw_stocks_all";
    SignalrTopic["os_futures"] = "os_futures";
    /** 五檔掛單報價 */
    SignalrTopic["bidask"] = "bidask";
    /** 逐筆成交 */
    SignalrTopic["tick"] = "tick";
})(SignalrTopic || (SignalrTopic = {}));
