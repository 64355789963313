import { css } from '@emotion/react';
import { memo } from 'react';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { ICAuthorizeView, LoginView, } from '~/modules/auth/components/UserSubscriptionCheckAsFullscreen';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
export const Preset_LoginPage = memo(function Preset_LoginPage(props) {
    const { meFirebaseState } = useMeStore();
    // 有登入
    return (<div data-testid='Preset_LoginPage' className={props.className} css={css `
        position: fixed;
        ${flex.v.allCenter};
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.9);
        z-index: 1300;
      `}>
      {/* 沒登入 */}
      {!meFirebaseState && <LoginView />}

      {/* 有登入；if(canAccess) 會放在 template 裡面驗，當 canAccess 時，就不用 render 這個 Preset_LoginPage 了 */}
      {meFirebaseState && <ICAuthorizeView />}

      {/* 沒登入時，的模糊背景圖 */}
      {!meFirebaseState && (<div css={css `
            filter: blur(4px) opacity(80%);
            background: url(${props.backgroundImage}) 0% 0% / cover no-repeat;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
          `}></div>)}
    </div>);
});
