import { useFavicon } from '@mantine/hooks';
import Head from 'next/head';
import { Fragment, memo, useMemo } from 'react';
import { useTitle } from 'react-use';
import { proxy, ref, useSnapshot } from 'valtio';
import { debugAPI } from '~/modules/SDK/debug/debugAPI';
import { FrError } from '~/modules/SDK/FrError/FrError';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { useSignalrBuild } from '~/modules/SDK/Signalr/useSignalrBuild';
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
import { fr_instrument } from '~/modules/SDK/FrInstrument/FrInstrument';
import { Preset_LoginPage } from '~/pages/heineken_template/_preset/preset_LoginPage';
import { Charting } from '~/pages/heineken_template/_private/Charting';
import { TemplatePage } from '~/pages/heineken_template/_private/TemplatePage';
import { component } from '~/utils/component';
export class TemplateProps {
    /**
     * 將會被 render 到 <Head> 裡面的：
     *
     * - `<title>`
     * - `<meta name='description'>`
     * - `<meta name='keywords'>`
     */
    page = proxy({
        title: '',
        description: '',
        keywords: '',
    });
    /**
     * # 存放全局覆寫 cssset 的地方
     *
     * @example
     *   //
     *   // 例如你要在覆寫投顧層級的全局 FuiButton 的 cssset 可以塞這裡
     *   store.globalCssset = css`
     *     ${FuiButton.classes.Root} {
     *       outline: 1px solid red;
     *     }
     *   `
     */
    globalCssset = undefined;
    onPageEnter = () => {
        //
    };
    onPageLeave = () => {
        //
    };
    permissions = {
        /**
         * - 它只管用來判斷「整頁」是否有讀取權，整頁都沒權，就展示 「layout.login」這個區塊
         * - 它不管「頁面中的小 HTML 區塊」是否有讀取權
         * - - 因此預期小區塊能夠被客製更細膩的「權限展示邏輯」
         *
         * @example
         *   //
         *   // 如果你有需要使用 fr_me 來取代 useMeStore, useFirebaseMeState, etc
         *   // 你可以
         *
         *   //
         *   // 前置安裝一次
         *   templateProps.hooks.add(fr_me.useInstall)
         *
         *   // 配置 fr_me 的 permission addon
         *   templateProps.permissions.pageview = fr_me.toTemplate.permissions.hasPermission(
         *     AgentProduct['futuresai@web'],
         *   )
         */
        pageview: meCheckHandlerCreateByProductNameWithExpiredAt('web'),
    };
    /**
     * @example
     *   //
     *   //
     *   templateProps.useLayout = function useLayout(layouts) {
     *     const { isPc } = useMedia()
     *
     *     return {
     *       ...layouts,
     *       //
     *       // 以此類推
     *       Row2: Futuresai_Row2,
     *       //
     *       // 例如這裡 Col1 只須要在 非PC 展示
     *       Col1: !isPc ? null : Futuresai_Col1,
     *     }
     *   }
     */
    layout = {
        cssset: undefined,
        login: Preset_LoginPage,
        Row1: undefined,
        Row2: undefined,
        Row3: undefined,
        Row4: undefined,
        Row5: undefined,
        Row6: undefined,
        Col1: undefined,
        Col2: undefined,
        Col3: undefined,
        Col4: undefined,
        Col5: undefined,
        Col6: undefined,
        Drawer1: undefined,
        Drawer2: undefined,
        Charting: Charting,
        _Providers: memo(function EmptyProvider(props) {
            return <Fragment>{props.children}</Fragment>;
        }),
        get Providers() {
            return this._Providers;
        },
        set Providers(ProviderComponent) {
            this._Providers = component(ProviderComponent);
        },
    };
    constructor() {
        debugAPI.template.log(`new TemplateProps()`);
    }
    useLayout = layouts => {
        return useMemo(() => {
            return {
                ...layouts,
            };
        }, [layouts]);
    };
    /**
     * 提供熱插拔安裝一些額外功能的地方
     *
     * @example
     *   //
     *   // 安裝 fr_me 權限控管
     *   templateProps.hooks.add(fr_me.useInstall)
     *
     * @example
     *   //
     *   // 更多範例
     *   templateProps.hooks.add(() => {
     *     const state = useSnapshot(futuresai_store)
     *
     *     useEffect(() => {
     *       signalrStore2.addQuote(state.quoteSymbols)
     *
     *       return () => {
     *         signalrStore2.removeQuote(state.quoteSymbols)
     *       }
     *     }, [state.quoteSymbols])
     *   })
     */
    hooks = {
        _collection: new Set(),
        add(UseHookGetter) {
            this._collection.add(UseHookGetter);
        },
        Provider: ref(component(props => {
            this.hooks._collection.forEach(useHook => {
                useHook();
            });
            return <Fragment>{props.children}</Fragment>;
        })),
    };
    /**
     * # @deprecated
     *
     * 廢棄，因為會很難繼承附加上去
     *
     * ## 使用 {@link TemplateProps.hooks}
     *
     * 提供熱插拔安裝一些額外功能的地方
     *
     * @example
     *   templateProps.useGlobalHooks = () => {
     *     //
     *     // 安裝舊版 signalrStore
     *     useSignalrBuild({ reconnectCheckInterval: 10000 })
     *     useSignalrStoreValueOHLC.getState().useValueUpdateInterval(500)
     *
     *     //
     *     // 安裝 fr_me
     *     fr_me.useInstall()
     *   }
     */
    useGlobalHooks = () => {
        useSignalrBuild({ reconnectCheckInterval: 10000 });
        useSignalrStoreValueOHLC.getState().useValueUpdateInterval(500);
    };
    /**
     * @example
     *   //
     *   // 懶人 NextPage 用法
     *
     *   const templateProps = new TemplateProps()
     *
     *   //
     *   // 設定頁面 title ,etc
     *   templateProps.page.title = '即時指標'
     *   templateProps.page.description = '即時指標'
     *
     *   //
     *   // templateProps.layout.Col1 = Futuresai_Col1
     *   // templateProps.layout.Col2 = Futuresai_Col2
     *   // 略略略
     *   //
     *
     *   //
     *   // 然後這樣 export 出去，就完成了
     *   //
     *   export default templateProps.NextPage
     */
    NextPage = () => {
        debugAPI.template.log(`<templateProps.NextPage /> 渲染`, this);
        const title = useSnapshot(this.page).title || fr_agents.config.title;
        useTitle(title);
        useFavicon(fr_agents.config.favicon);
        return (<Fragment>
        <Head>
          <title>{title}</title>
          <meta name='description' content={this.page.description}/>
          <meta name='keywords' content={this.page.keywords}/>
        </Head>

        <FrError.ComponentBoundary filepath='pages/heineken_template/_private/TemplateProps.tsx'>
          <fr_instrument.DataProvider>
            <TemplatePage templateProps={this}/>
          </fr_instrument.DataProvider>
        </FrError.ComponentBoundary>
      </Fragment>);
    };
}
