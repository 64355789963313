import { proxy } from 'valtio';
import { ChartingModule } from '~/modules/SDK/chart4/ChartingModule';
import { FuiDrawer } from '~/pages/heineken_template/components/FuiDrawer';
/**
 * # templateStore
 *
 * ## 正在考慮改名為 templateStore
 */
export const store = proxy({
    charting: proxy(new ChartingModule()),
    drawerLeft: new FuiDrawer({ position: 'left' }),
    drawerRight: new FuiDrawer({ position: 'right' }),
});
store.charting.widgetOptions = {
    ...store.charting.widgetOptions,
    overrides: {
        'mainSeriesProperties.candleStyle.upColor': '#ff4000',
        'mainSeriesProperties.candleStyle.downColor': '#00ff7b',
    },
};
store.charting.darkOverrides = {
    'mainSeriesProperties.candleStyle.upColor': '#ff4000',
    'mainSeriesProperties.candleStyle.downColor': '#00ff7b',
};
