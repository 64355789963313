import { Fragment, isValidElement, useMemo } from 'react';
/**
 * # 把 ReactElement 轉成 ComponentType
 *
 * @example
 *   //
 *   // 內部機制
 *   //
 *   // 當，如果你傳入的是 `React.ReactElement`
 *   // 例如：
 *   //
 *
 *   const Pane1 = memo(function Pane1() {
 *     const charting = useSnapshot(template_store.charting)
 *
 *     //
 *     // 若沒有 memo，此 useEffect(, [charting.symbol]) 會無效；
 *     // 因為每一次都被「此hook」執行並返回了「新的組件引用」；
 *     // 此時 console 將會無視 deps 而無限執行
 *     //
 *     useEffect(() => {
 *       console.info('mount', charting.symbol)
 *
 *       return () => {
 *         console.info('unmount', charting.symbol)
 *       }
 *     }, [charting.symbol])
 *   })
 *
 *   templateProps.Drawer1 = (
 *     <div>
 *       <Pane1 />
 *     </div>
 *   )
 *
 *   //
 *   // 那麼在「此hook」裡，我們就需要將它 memo 起來，以使避免每一次 render-time 都被返回一個「新的引用」
 *   //
 */
export const useElementAsComponent = (component) => {
    const ValidElementToComponent = useMemo(() => {
        if (!component) {
            return function Component() {
                return null;
            };
        }
        if (isValidElement(component)) {
            return function Component() {
                return <Fragment>{component}</Fragment>;
            };
        }
        return component;
    }, [component]);
    // FIXME: 這是 typing errror 但我目前不知道怎摹修
    /** @ts-expect-error 若亮紅線代表要修到不亮線 */
    return ValidElementToComponent;
};
